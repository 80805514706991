import { CDN_PATH } from "../config/config";

export type ClubInformation = {
  domain: string;
  clubCode: string;
  clubName: string;
  hometown: string;
  division: string;
  logo: {
    url: string;
    width: number;
    height: number;
  };
  primary: {
    main: string;
    light: string;
    dark: string;
  };
  secondary: string;
  clubId: string;
  clubGradeSort?: string;
  clubGradePrecision?: number;
  positions: ClubPosition[];
};

export type ClubPosition = {
  name: string;
  group: string;
};

export const clubInformation: ClubInformation[] = [
  {
    domain: "cardinals.nfl.com",
    clubCode: "ARZ",
    clubName: "Arizona Cardinals",
    hometown: "Arizona",
    division: "NFC West",
    logo: {
      url: `${CDN_PATH}/pro/logos/transparent/ARZ.gif`,
      width: 600,
      height: 401,
    },
    primary: {
      main: "#870619",
      light: "grey",
      dark: "black",
    },
    secondary: "#000000",
    clubId: "29bc477a-e5b7-57dd-b73c-ad9783e85b3c",
    positions: [],
  },
  {
    domain: "falcons.nfl.com",
    clubCode: "ATL",
    clubName: "Atlanta Falcons",
    hometown: "Atlanta",
    division: "NFC South",

    logo: {
      url: `${CDN_PATH}/pro/logos/transparent/ATL.gif`,
      width: 600,
      height: 401,
    },
    primary: {
      main: "#BD0D18",
      light: "grey",
      dark: "black",
    },
    secondary: "#000000",
    clubId: "05b24043-bb4c-50e7-88f6-f2e43146b5a2",
    positions: [],
  },
  {
    domain: "ravens.nfl.com",
    clubCode: "BLT",
    clubName: "Baltimore Ravens",
    hometown: "Baltimore",
    division: "AFC North",
    logo: {
      url: `${CDN_PATH}/pro/logos/transparent/BLT.gif`,
      width: 600,
      height: 401,
    },
    primary: {
      main: "#280353",
      light: "grey",
      dark: "black",
    },
    secondary: "#000000",
    clubId: "a2c18dc9-2b95-595a-91c1-c07d8bf10ffc",
    positions: [],
  },
  {
    domain: "bills.nfl.com",
    clubCode: "BUF",
    clubName: "Buffalo Bills",
    hometown: "Buffalo",
    division: "AFC East",
    logo: {
      url: `${CDN_PATH}/pro/logos/transparent/BUF.gif`,
      width: 600,
      height: 401,
    },
    primary: {
      main: "#00338D",
      light: "grey",
      dark: "black",
    },
    secondary: "#C60C30",
    clubId: "645fddd1-df20-5323-93e4-c7c176baa507",
    positions: [],
  },
  {
    domain: "panthers.nfl.com",
    clubCode: "CAR",
    clubName: "Carolina Panthers",
    hometown: "Carolina",
    division: "NFC South",
    logo: {
      url: `${CDN_PATH}/pro/logos/transparent/CAR.gif`,
      width: 600,
      height: 401,
    },
    primary: {
      main: "#0085CA",
      light: "#D9EDF7",
      dark: "#075177",
    },
    secondary: "#BFC0BF",
    clubId: "b0afc250-bc45-565b-ab31-c09e2d5209e8",
    clubGradeSort: "asc",
    clubGradePrecision: 2,
    positions: [
      { name: "DC", group: "Defense" },
      { name: "DE", group: "Defense" },
      { name: "DS", group: "Defense" },
      { name: "ILB", group: "Defense" },
      { name: "OLB", group: "Defense" },
      { name: "NKL", group: "Defense" },
      { name: "NT", group: "Defense" },
      { name: "QB", group: "Offense" },
      { name: "WR", group: "Offense" },
      { name: "SLOT", group: "Offense" },
      { name: "RB", group: "Offense" },
      { name: "FB/F", group: "Offense" },
      { name: "TE", group: "Offense" },
      { name: "OC", group: "Offense" },
      { name: "OG", group: "Offense" },
      { name: "LT", group: "Offense" },
      { name: "RT", group: "Offense" },
      { name: "SPEC", group: "Special Teams" },
      { name: "LS", group: "Special Teams" },
      { name: "PK", group: "Special Teams" },
      { name: "PT", group: "Special Teams" },
    ],
  },
  {
    domain: "bears.nfl.com",
    clubCode: "CHI",
    clubName: "Chicago Bears",
    hometown: "Chicago",
    division: "NFC North",
    logo: {
      url: `${CDN_PATH}/pro/logos/transparent/CHI.gif`,
      width: 600,
      height: 401,
    },
    primary: {
      main: "#03202F",
      light: "grey",
      dark: "black",
    },
    secondary: "#DD4814",
    clubId: "a939ecc7-fdbe-5f71-af12-b480efb5ffeb",
    positions: [],
  },
  {
    domain: "bengals.nfl.com",
    clubCode: "CIN",
    clubName: "Cincinnati Bengals",
    hometown: "Cincinnati",
    division: "AFC North",
    logo: {
      url: `${CDN_PATH}/pro/logos/transparent/CIN.gif`,
      width: 600,
      height: 401,
    },
    primary: {
      main: "#000000",
      light: "grey",
      dark: "black",
    },
    secondary: "#FB4F14",
    clubId: "d6ee26d1-1c8a-5599-a3bd-1537e7de85bc",
    positions: [],
  },
  {
    domain: "browns.nfl.com",
    clubCode: "CLV",
    clubName: "Cleveland Browns",
    hometown: "Cleveland",
    division: "AFC North",
    logo: {
      url: `${CDN_PATH}/pro/logos/transparent/CLV.gif`,
      width: 600,
      height: 401,
    },
    primary: {
      main: "#26201E",
      light: "grey",
      dark: "black",
    },
    secondary: "#E34912",
    clubId: "becdb626-1b97-5904-9eed-71842375bc39",
    positions: [],
  },
  {
    domain: "cowboys.nfl.com",
    clubCode: "DAL",
    clubName: "Dallas Cowboys",
    hometown: "Dallas",
    division: "NFC East",
    logo: {
      url: `${CDN_PATH}/pro/logos/transparent/DAL.gif`,
      width: 600,
      height: 401,
    },
    primary: {
      main: "#002244",
      light: "grey",
      dark: "black",
    },
    secondary: "#8C8B8A",
    clubId: "1afbdd15-d429-5bcb-a55b-af7199345da5",
    positions: [],
  },
  {
    domain: "broncos.nfl.com",
    clubCode: "DEN",
    clubName: "Denver Broncos",
    hometown: "Denver",
    division: "AFC West",
    logo: {
      url: `${CDN_PATH}/pro/logos/transparent/DEN.gif`,
      width: 600,
      height: 401,
    },
    primary: {
      main: "#FB4F14",
      light: "grey",
      dark: "black",
    },
    secondary: "#002244",
    clubId: "30a0f7e1-9843-5edd-8cf1-c60918b7f861",
    positions: [],
  },
  {
    domain: "lions.nfl.com",
    clubCode: "DET",
    clubName: "Detroit Lions",
    hometown: "Detroit",
    division: "NFC North",
    logo: {
      url: `${CDN_PATH}/pro/logos/transparent/DET.gif`,
      width: 600,
      height: 401,
    },
    primary: {
      main: "#006DB0",
      light: "grey",
      dark: "black",
    },
    secondary: "#C5C7CF",
    clubId: "9bbd6453-9703-5015-8a11-1a607b8805bd",
    positions: [],
  },
  {
    domain: "packers.nfl.com",
    clubCode: "GB",
    clubName: "Green Bay Packers",
    hometown: "Green Bay",
    division: "NFC North",
    logo: {
      url: `${CDN_PATH}/pro/logos/transparent/GB.gif`,
      width: 600,
      height: 401,
    },
    primary: {
      main: "#213D30",
      light: "grey",
      dark: "black",
    },
    secondary: "#FFCC00",
    clubId: "63d59843-d136-5f8b-9c55-de09a20ab321",
    positions: [],
  },
  {
    domain: "texans.nfl.com",
    clubCode: "HST",
    clubName: "Houston Texans",
    hometown: "Houston",
    division: "AFC South",
    logo: {
      url: `${CDN_PATH}/pro/logos/transparent/HST.gif`,
      width: 600,
      height: 401,
    },
    primary: {
      main: "#02253A",
      light: "grey",
      dark: "black",
    },
    secondary: "#B31B34",
    clubId: "e871178d-ca00-52ff-9e93-e3f7a8a9bc9f",
    positions: [],
  },
  {
    domain: "colts.nfl.com",
    clubCode: "IND",
    clubName: "Indianapolis Colts",
    hometown: "Indianapolis",
    division: "AFC South",
    logo: {
      url: `${CDN_PATH}/pro/logos/transparent/IND.gif`,
      width: 600,
      height: 401,
    },
    primary: {
      main: "#003B7B",
      light: "grey",
      dark: "black",
    },
    secondary: "#FFFFFF",
    clubId: "632cbb59-f592-5648-b181-6b23bdf9d85d",
    positions: [],
  },
  {
    domain: "jaguars.nfl.com",
    clubCode: "JAX",
    clubName: "Jacksonville Jaguars",
    hometown: "Jacksonville",
    division: "AFC South",
    logo: {
      url: `${CDN_PATH}/pro/logos/transparent/JAX.gif`,
      width: 600,
      height: 401,
    },
    primary: {
      main: "#006778",
      light: "#D9E8EB",
      dark: "#0F282B",
    },
    secondary: "#D0B239",
    clubId: "b0d7698e-edf7-5afe-98fe-85827218adb9",
    positions: [],
  },
  {
    domain: "chiefs.nfl.com",
    clubCode: "KC",
    clubName: "Kansas City Chiefs",
    hometown: "Kansas City",
    division: "AFC West",
    logo: {
      url: `${CDN_PATH}/pro/logos/transparent/KC.gif`,
      width: 600,
      height: 401,
    },
    primary: {
      main: "#B20032",
      light: "grey",
      dark: "black",
    },
    secondary: "#F2C800",
    clubId: "3baabdb4-8c73-58f8-8e87-8db435aefb16",
    positions: [],
  },
  {
    domain: "rams.nfl.com",
    clubCode: "LA",
    clubName: "Los Angeles Rams",
    hometown: "Los Angeles",
    division: "NFC West",
    logo: {
      url: `${CDN_PATH}/pro/logos/transparent/LA.gif`,
      width: 600,
      height: 401,
    },
    primary: {
      main: "#002A5B",
      light: "grey",
      dark: "black",
    },
    secondary: "#C6A875",
    clubId: "252e01fc-2471-563e-aa87-4876e74fbf06",
    positions: [],
  },
  {
    domain: "chargers.nfl.com",
    clubCode: "LAC",
    clubName: "Los Angeles Chargers",
    hometown: "Los Angeles",
    division: "AFC West",
    logo: {
      url: `${CDN_PATH}/pro/logos/transparent/LAC.gif`,
      width: 600,
      height: 401,
    },
    primary: {
      main: "#08214A",
      light: "grey",
      dark: "black",
    },
    secondary: "#EEC607",
    clubId: "dd0c9c81-33c9-5cc9-bee2-7c26a6327f6c",
    positions: [],
  },
  {
    domain: "raiders.nfl.com",
    clubCode: "LV",
    clubName: "Las Vegas Raiders",
    hometown: "Las Vegas",
    division: "AFC West",
    logo: {
      url: `${CDN_PATH}/pro/logos/transparent/OAK.gif`,
      width: 600,
      height: 401,
    },
    primary: {
      main: "#C4C8CB",
      light: "grey",
      dark: "black",
    },
    secondary: "#000000",
    clubId: "bb3667cc-9ac6-5170-9572-2b840882facd",
    positions: [],
  },
  {
    domain: "dolphins.nfl.com",
    clubCode: "MIA",
    clubName: "Miami Dolphins",
    hometown: "Miami",
    division: "AFC East",
    logo: {
      url: `${CDN_PATH}/pro/logos/transparent/MIA.gif`,
      width: 600,
      height: 401,
    },
    primary: {
      main: "#008D97",
      light: "grey",
      dark: "black",
    },
    secondary: "#015679",
    clubId: "4857a921-f6c9-5357-8527-f8589332184b",
    positions: [],
  },
  {
    domain: "vikings.nfl.com",
    clubCode: "MIN",
    clubName: "Minnesota Vikings",
    hometown: "Minnesota",
    division: "NFC North",
    logo: {
      url: `${CDN_PATH}/pro/logos/transparent/MIN.gif`,
      width: 600,
      height: 401,
    },
    primary: {
      main: "#4F2682",
      light: "grey",
      dark: "black",
    },
    secondary: "#FFC52F",
    clubId: "2315da89-83d2-5174-b6a7-1e81d98032a2",
    positions: [],
  },
  {
    domain: "patriots.nfl.com",
    clubCode: "NE",
    clubName: "New England Patriots",
    hometown: "New England",
    division: "AFC East",
    logo: {
      url: `${CDN_PATH}/pro/logos/transparent/NE.gif`,
      width: 600,
      height: 401,
    },
    primary: {
      main: "#0D254C",
      light: "grey",
      dark: "black",
    },
    secondary: "#D6D6D6",
    clubId: "cb8fb75c-0a4c-5080-8580-9b2d46e98591",
    positions: [
      { name: "CB", group: "Defense" },
      { name: "DE", group: "Defense" },
      { name: "DT", group: "Defense" },
      { name: "LB", group: "Defense" },
      { name: "SAF", group: "Defense" },
      { name: "QB", group: "Offense" },
      { name: "WR", group: "Offense" },
      { name: "RB", group: "Offense" },
      { name: "FB", group: "Offense" },
      { name: "TE", group: "Offense" },
      { name: "OC", group: "Offense" },
      { name: "OG", group: "Offense" },
      { name: "OT", group: "Offense" },
      { name: "SP", group: "Special Teams" },
    ],
  },
  {
    domain: "saints.nfl.com",
    clubCode: "NO",
    clubName: "New Orleans Saints",
    hometown: "New Orleans",
    division: "NFC South",
    logo: {
      url: `${CDN_PATH}/pro/logos/transparent/NO.gif`,
      width: 600,
      height: 401,
    },
    primary: {
      main: "#D2B887",
      light: "grey",
      dark: "black",
    },
    secondary: "#000000",
    clubId: "361e23bb-82f8-554d-b60d-24ac2d7679c3",
    positions: [],
  },
  {
    domain: "giants.nfl.com",
    clubCode: "NYG",
    clubName: "New York Giants",
    hometown: "New York",
    division: "NFC East",
    logo: {
      url: `${CDN_PATH}/pro/logos/transparent/NYG.gif`,
      width: 600,
      height: 401,
    },
    primary: {
      main: "#192F6B",
      light: "grey",
      dark: "black",
    },
    secondary: "#CA001A",
    clubId: "8f5bf2a9-6ce9-5164-988e-2e0062f32090",
    positions: [],
  },
  {
    domain: "jets.nfl.com",
    clubCode: "NYJ",
    clubName: "New York Jets",
    hometown: "New York",
    division: "AFC East",
    logo: {
      url: `${CDN_PATH}/pro/logos/transparent/NYJ.gif`,
      width: 600,
      height: 401,
    },
    primary: {
      main: "#0C371D",
      light: "grey",
      dark: "black",
    },
    secondary: "#FFFFFF",
    clubId: "84f58345-4a56-5c30-92b4-7365ac46b68f",
    positions: [],
  },
  {
    domain: "eagles.nfl.com",
    clubCode: "PHI",
    clubName: "Philadelphia Eagles",
    hometown: "Philadelphia",
    division: "NFC East",
    logo: {
      url: `${CDN_PATH}/pro/logos/transparent/PHI.gif`,
      width: 600,
      height: 401,
    },
    primary: {
      main: "#003B48",
      light: "grey",
      dark: "black",
    },
    secondary: "#000000",
    clubId: "eb0beb8f-2a1e-51e2-9ab2-793f72994559",
    positions: [],
  },
  {
    domain: "steelers.nfl.com",
    clubCode: "PIT",
    clubName: "Pittsburgh Steelers",
    hometown: "Pittsburgh",
    division: "AFC North",
    logo: {
      url: `${CDN_PATH}/pro/logos/transparent/PIT.gif`,
      width: 600,
      height: 401,
    },
    primary: {
      main: "#000000",
      light: "grey",
      dark: "black",
    },
    secondary: "#F2C800",
    clubId: "995a2dd0-93bd-5651-a60d-6708b62b17b3",
    positions: [],
  },
  {
    domain: "seahawks.nfl.com",
    clubCode: "SEA",
    clubName: "Seattle Seahawks",
    hometown: "Seattle",
    division: "NFC West",
    logo: {
      url: `${CDN_PATH}/pro/logos/transparent/SEA.gif`,
      width: 600,
      height: 401,
    },
    primary: {
      main: "#06192E",
      light: "grey",
      dark: "black",
    },
    secondary: "#4EAE47",
    clubId: "bc17557b-b749-58b5-9404-8682a796a9d2",
    positions: [],
  },
  {
    domain: "49ers.nfl.com",
    clubCode: "SF",
    clubName: "San Francisco 49ers",
    hometown: "San Francisco",
    division: "NFC West",
    logo: {
      url: `${CDN_PATH}/pro/logos/transparent/SF.gif`,
      width: 600,
      height: 401,
    },
    primary: {
      main: "#AF1E2C",
      light: "grey",
      dark: "black",
    },
    secondary: "#E6BE8A",
    clubId: "88397334-0092-5b55-8ce1-2bd467e0edf8",
    positions: [
      { name: "CB", group: "Defense" },
      { name: "DE", group: "Defense" },
      { name: "DT", group: "Defense" },
      { name: "LB", group: "Defense" },
      { name: "SAF", group: "Defense" },
      { name: "QB", group: "Offense" },
      { name: "WR", group: "Offense" },
      { name: "RB", group: "Offense" },
      { name: "TE", group: "Offense" },
      { name: "OC", group: "Offense" },
      { name: "OG", group: "Offense" },
      { name: "OT", group: "Offense" },
      { name: "ST", group: "Special Teams" },
    ],
  },
  {
    domain: "buccaneers.nfl.com",
    clubCode: "TB",
    clubName: "Tampa Bay Buccaneers",
    hometown: "Tampa Bay",
    division: "NFC South",
    logo: {
      url: `${CDN_PATH}/pro/logos/transparent/TB.gif`,
      width: 600,
      height: 401,
    },
    primary: {
      main: "#D60A0B",
      light: "grey",
      dark: "black",
    },
    secondary: "#89765F",
    clubId: "07f46f9d-1c41-534e-ab68-574e82c94a46",
    positions: [],
  },
  {
    domain: "titans.nfl.com",
    clubCode: "TEN",
    clubName: "Tennessee Titans",
    hometown: "Tennessee",
    division: "AFC South",
    logo: {
      url: `${CDN_PATH}/pro/logos/transparent/TEN.gif`,
      width: 600,
      height: 401,
    },
    primary: {
      main: "#648FCC",
      light: "grey",
      dark: "black",
    },
    secondary: "#000080",
    clubId: "b4a5ebdf-c1ab-5046-9985-a69f83a44ace",
    positions: [],
  },
  {
    domain: "commanders.nfl.com",
    clubCode: "WAS",
    clubName: "Washington Commanders",
    hometown: "Washington",
    division: "NFC East",
    logo: {
      url: `${CDN_PATH}/pro/logos/transparent/WAS.gif`,
      width: 600,
      height: 401,
    },
    primary: {
      main: "#773141",
      light: "grey",
      dark: "black",
    },
    secondary: "#FFB612",
    clubId: "e3003a6c-2896-5b55-9ede-2d9a2b16dca2",
    positions: [],
  },
];

export const getClubFromCode = (clubCode?: string) => {
  const club = clubInformation.find((club) => club.clubCode === clubCode);
  return club;
};

export const getClubCode = (clubId?: string) => {
  const club = clubInformation.find((club) => club.clubId === clubId);
  return club?.clubCode;
};

export const getClubName = (clubId?: string) => {
  const club = clubInformation.find((club) => club.clubId === clubId);
  return club?.clubName;
};

export const getClubId = (clubCode?: string) => {
  const club = clubInformation.find((club) => club.clubCode === clubCode);
  return club?.clubId;
};

export const clubGradeSortOrder = (clubCode: string) => {
  const club = clubInformation.find((club) => club.clubCode === clubCode);
  return club?.clubGradeSort === "asc" ? "asc" : "desc";
};

export const getClubsByDivision = (division: string) => {
  return clubInformation.filter((club) => club.division === division);
};
export const getClubFromId = (clubId?: string) => {
  const club = clubInformation.find((club) => club.clubId === clubId);
  return club;
};
export enum DivisionEnum {
  NFCSouth = "NFC South",
  NFCNorth = "NFC North",
  NFCEast = "NFC East",
  NFCWest = "NFC West",
  AFCSouth = "AFC South",
  AFCNorth = "AFC North",
  AFCEast = "AFC East",
  AFCWest = "AFC West",
}

export const getSideOfBall = (position: string) => {
  const positionFilters = [
    { sideOfBall: "Defense", name: "CB" },
    { sideOfBall: "Defense", name: "DC" },
    { sideOfBall: "Defense", name: "DE" },
    { sideOfBall: "Defense", name: "DS" },
    { sideOfBall: "Defense", name: "DT" },
    { sideOfBall: "Defense", name: "FS" },
    { sideOfBall: "Defense", name: "ILB" },
    { sideOfBall: "Defense", name: "LB" },
    { sideOfBall: "Defense", name: "MLB" },
    { sideOfBall: "Defense", name: "NKL" },
    { sideOfBall: "Defense", name: "NT" },
    { sideOfBall: "Defense", name: "OLB" },
    { sideOfBall: "Defense", name: "SAF" },
    { sideOfBall: "Defense", name: "SS" },
    { sideOfBall: "Defense", name: "ED" },
    { sideOfBall: "Defense", name: "DI" },
    { sideOfBall: "Defense", name: "S" },
    { sideOfBall: "Defense", name: "OB" },
    { sideOfBall: "Defense", name: "IB" },

    { sideOfBall: "Offense", name: "C" },
    { sideOfBall: "Offense", name: "FB" },
    { sideOfBall: "Offense", name: "FB/F" },
    { sideOfBall: "Offense", name: "LG" },
    { sideOfBall: "Offense", name: "LT" },
    { sideOfBall: "Offense", name: "T" },
    { sideOfBall: "Offense", name: "OC" },
    { sideOfBall: "Offense", name: "OG" },
    { sideOfBall: "Offense", name: "OT" },
    { sideOfBall: "Offense", name: "QB" },
    { sideOfBall: "Offense", name: "RB" },
    { sideOfBall: "Offense", name: "HB" },
    { sideOfBall: "Offense", name: "RG" },
    { sideOfBall: "Offense", name: "RT" },
    { sideOfBall: "Offense", name: "SLOT" },
    { sideOfBall: "Offense", name: "TE" },
    { sideOfBall: "Offense", name: "WR" },
    { sideOfBall: "Offense", name: "G" },

    { sideOfBall: "Special Teams", name: "H" },
    { sideOfBall: "Special Teams", name: "K" },
    { sideOfBall: "Special Teams", name: "KR" },
    { sideOfBall: "Special Teams", name: "LS" },
    { sideOfBall: "Special Teams", name: "P" },
    { sideOfBall: "Special Teams", name: "PK" },
    { sideOfBall: "Special Teams", name: "PR" },
    { sideOfBall: "Special Teams", name: "PT" },
    { sideOfBall: "Special Teams", name: "SP" },
    { sideOfBall: "Special Teams", name: "SPEC" },
    { sideOfBall: "Special Teams", name: "ST" },
    { sideOfBall: "Special Teams", name: "RS" },
  ];

  return positionFilters.find((f) => f.name === position)?.sideOfBall ?? "Misc";
};
